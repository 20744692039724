<template>
  <div id="view-site-audit" class="mt-3 pt-3 pb-3">
    <!-- <div class="text-right px-4 px-md-0 pb-4">
      <button
        class="btn btn-sm btn-primary rounded-pill px-3 font-weight-medium"
        @click="openModelModal(true)"
      >
        + {{ site && site.model_url ? "Edit URL" : "Add URL" }}
      </button>
    </div> -->
    <div class="content-item overflow-hidden">
      <p>AUDIT PAGE</p>
    </div>
  </div>
</template>

<script>
import Modal from "@/components/Modal";

export default {
  name: "site-audit-view",
  props: ["site"],
  components: {
    Modal,
  },
  data() {
    return {
      showModal: {
        audit: false,
      },
    };
  },
  methods: {
    toggleSpinner(e) {
      this.$emit("toggleSpinner", e);
    },
  },
  mounted() {},
};
</script>